<template>
  <div>
    <div class="row">
      <div class="col-md-12 text-start">
        <h5><strong>Manteniminentos</strong></h5>
        <hr />
      </div>
    </div>
    <div class="about row mt-4">
      <div class="col-md-3">
        <a
          @click="$router.push('Crud/CabinetBelow')"
          class="btn btn-lg btn-primary w-100"
          ><i class="fa fa-link"> </i> Gabinete Inferior
        </a>
      </div>
      <div class="col-md-3">
        <a
          @click="$router.push('Crud/CabinetAbove')"
          class="btn btn-lg btn-primary w-100"
          ><i class="fa fa-link"> </i> Gabinete Superior
        </a>
      </div>
      <div class="col-md-3">
        <a
          class="btn btn-lg btn-primary w-100"
          @click="$router.push('Crud/ConstructionSystem')"
          ><i class="fa fa-link"> </i> Sistemas Constructivos
        </a>
      </div>
      <div class="col-md-3">
        <a
          class="btn btn-lg btn-primary w-100"
          @click="$router.push('Crud/Doors')"
          ><i class="fa fa-link"> </i> Tipos de Puertas
        </a>
      </div>
    </div>
    <div class="mt-4 row">
      <div class="col-md-3">
        <a class="btn btn-lg btn-primary w-100"
          ><i class="fa fa-link"> </i> Pintura Exterior
        </a>
      </div>
      <div class="col-md-3">
        <a class="btn btn-lg btn-primary w-100"
          ><i class="fa fa-link"> </i> Pintura Interior
        </a>
      </div>
      <div class="col-md-3">
        <a class="btn btn-lg btn-primary w-100"
          ><i class="fa fa-link"> </i> Tipos de Pisos
        </a>
      </div>
      <div class="col-md-3">
        <a class="btn btn-lg btn-primary w-100"
          ><i class="fa fa-link"> </i> Tipos de Vivienda
        </a>
      </div>
    </div>
    <div class="mt-4 row">
      <div class="col-md-3">
        <a class="btn btn-lg btn-primary w-100"
          ><i class="fa fa-link"> </i> Terminación Exterior
        </a>
      </div>
      <div class="col-md-3">
        <a class="btn btn-lg btn-primary w-100"
          ><i class="fa fa-link"> </i> Terminación Interior
        </a>
      </div>
      <div class="col-md-3">
        <a class="btn btn-lg btn-primary w-100"
          ><i class="fa fa-link"> </i> Tipos de Hierros
        </a>
      </div>
      <div class="col-md-3">
        <a class="btn btn-lg btn-primary w-100"
          ><i class="fa fa-link"> </i> Tipos de Cocina
        </a>
      </div>
    </div>
    <div class="mt-4 row">
      <div class="col-md-3">
        <a class="btn btn-lg btn-primary w-100"
          ><i class="fa fa-link"> </i> Ventanas
        </a>
      </div>
      <div class="col-md-3">
        <a class="btn btn-lg btn-primary w-100"
          ><i class="fa fa-link"> </i> Tipología
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Cruds",
};
</script>
